export const formatPayMethodKey = (methodName: string, separator?: '-'|'_') => {
  if (!methodName) return '';

  try {
    if (separator === '-') {
      return methodName
        .split(/[.]/).join(separator);
    }

    if (separator === '_') {
      return methodName
        .split(/[-.]/).join(separator);
    }

    return methodName
      .split(/[-_.]/)
      .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  } catch (e) {
    return methodName;
  }
};
